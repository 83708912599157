import { RealtimeChannel, RealtimePostgresUpdatePayload } from "@supabase/realtime-js";

import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column, ColumnFilterClearTemplateOptions, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta, DataTableFilterMetaData } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

import { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useImmerReducer } from "use-immer";

import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";

import Loader from "../../components/Loader";
import { exportExcel } from "../../libs/excel";

import { useAsyncError, useBatchNumber, useEvent } from "../../utils/hooks";
import { loadConstraints, loadVehiclesAndDealerships } from "./api";
import { vehicleUpdate } from "./events";
import { extractColleagueIds } from "./logic";

const BatchStatistics = lazy(() => import("../../components/BatchStatistics"));

interface State {
  vehicles: {
    data: Record<string, any>[];
    isLoading: boolean;
  };
  constraints: {
    data: { material: string; is_claimed: boolean; usage: number; max_limit: number }[];
    isLoading: boolean;
  };
  usersDealerships: {
    data: { [user_id: string]: { dealerNo: string } };
    isLoading: boolean;
  };
}

type Action =
  | { type: "SET_VEHICLES"; payload: { vehicles: Record<string, any>[] } }
  | {
      type: "SET_USERS_DEALERSHIPS";
      payload: { usersDealerships: { [user_id: string]: { dealerNo: string } } };
    }
  | {
      type: "SET_CONSTRAINTS";
      payload: { constraints: { material: string; is_claimed: boolean; usage: number; max_limit: number }[] };
    }
  | {
      type: "SET_LOADING";
      payload: { vehicles?: boolean; constraints?: boolean; usersDealerships?: boolean };
    };

function dataReducer(state: State, action: Action) {
  switch (action.type) {
    case "SET_VEHICLES":
      state.vehicles.data = action.payload.vehicles;
      break;
    case "SET_CONSTRAINTS":
      state.constraints.data = action.payload.constraints;
      break;
    case "SET_USERS_DEALERSHIPS":
      state.usersDealerships.data = action.payload.usersDealerships;
      break;
    case "SET_LOADING":
      if (action.payload.vehicles !== undefined) {
        state.vehicles.isLoading = action.payload.vehicles;
      }
      if (action.payload.constraints !== undefined) {
        state.constraints.isLoading = action.payload.constraints;
      }
      if (action.payload.usersDealerships !== undefined) {
        state.usersDealerships.isLoading = action.payload.usersDealerships;
      }
      break;
    default:
      break;
  }
}

const VehiclesListingPage = () => {
  const supabase = useSupabaseClient();
  const user = useUser();
  const isAdmin = user?.app_metadata.hyundairds_is_admin;
  const batchNumber = useBatchNumber();

  const [isClaiming, setIsClaiming] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const [state, dispatch] = useImmerReducer<State, Action>(dataReducer, {
    vehicles: { data: [], isLoading: true },
    constraints: { data: [], isLoading: true },
    usersDealerships: { data: {}, isLoading: true },
  });
  const [dealerships, setDealerships] = useState<{ [dealer_no: string]: { dealerName: string; userIds: string[] } }>({});

  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(undefined);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [filterColumnValues, setFilterColumnValues] = useState<{ [k: string]: string[] }>({});

  const toast = useRef<Toast>(null);
  const [showAdminStatistics, setShowAdminStatistics] = useState(false);

  useHotkeys("r", () => initFilters(), { preventDefault: true });
  useHotkeys("d", () => filterOnlyAvailable(), { preventDefault: true });
  useHotkeys("s", () => filterOnlyClaimed(), { preventDefault: true });

  const throwError = useAsyncError();

  const columns = [
    { field: "sales_order", header: "Objednávka", sortable: false, filterable: false, userExportable: true },
    { field: "vin", header: "VIN", sortable: false, filterable: false, userExportable: true },
    {
      field: "material",
      header: "Materiál",
      sortable: false,
      filterable: true,
      filterFactory: () => multiselectFilterBuilder("material"),
      userExportable: true,
    },
    {
      field: "material_description",
      header: "Popis materiálu",
      sortable: false,
      filterable: true,
      filterFactory: () => multiselectFilterBuilder("material_description"),
      userExportable: true,
    },
    {
      field: "exterior_color_code",
      header: "Exteriér",
      sortable: false,
      filterable: true,
      filterFactory: () => multiselectFilterBuilder("exterior_color_code"),
      userExportable: true,
    },
    {
      field: "interior_color_code",
      header: "Interiér",
      sortable: false,
      filterable: true,
      filterFactory: () => multiselectFilterBuilder("interior_color_code"),
      userExportable: true,
    },
    {
      field: "claimed_by",
      header: "Stav",
      sortable: false,
      templateFactory: () => claimTemplate,
      filterable: false,
      userExportable: false,
    },
  ];
  const columnNames = useRef(columns.map((col) => col.field));

  const filtersDefinition = useRef({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    material: { value: null, matchMode: FilterMatchMode.IN },
    material_description: { value: null, matchMode: FilterMatchMode.IN },
    exterior_color_code: { value: null, matchMode: FilterMatchMode.IN },
    interior_color_code: { value: null, matchMode: FilterMatchMode.IN },
    sales_order: { value: null, matchMode: FilterMatchMode.IN },
  });

  let channel = useRef<RealtimeChannel | null>(null);

  const userId = useMemo(() => {
    // prevent re-rendering of the page from happening when it is (re)-focused,
    // as supabase auth creates a new auth event, which mutates the user object,
    // which in turn invokes all `useEffects` due to the user dependecy
    return user?.id;
  }, [user]);

  const colleagueIds = useMemo(() => {
    const colleagueIds = extractColleagueIds(state.usersDealerships.data, dealerships, userId);

    return colleagueIds;
  }, [dealerships, state.usersDealerships, userId]);

  const onVehicleUpdatedEvent = useEvent((payload: RealtimePostgresUpdatePayload<{ [key: string]: any }>) => {
    console.log(`Received new event`, payload);

    const { vehicles: updatedVehicles, refreshConstraints } = vehicleUpdate(
      payload,
      { vehicles: state.vehicles.data, usersDealerships: state.usersDealerships.data },
      { isAdmin: isAdmin, userId: userId, colleagueIds: colleagueIds }
    );

    dispatch({
      type: "SET_VEHICLES",
      payload: {
        vehicles: updatedVehicles,
      },
    });

    if (refreshConstraints) {
      if (payload.new["claimed_by"] !== null && colleagueIds.has(payload.new["claimed_by"])) {
        toast.current?.show({
          severity: "info",
          summary: "Vozidlo úspešne vybraté iným kolegom! Limity sa prepočítavajú.",
          detail: `Rezervácia VIN ${payload.new["vin"]} (${payload.new["sales_order"]}) bola zaznamenaná.\n\nPre pokračovanie treba počkať dokým sa načítajú nové limity.`,
          life: 5000,
        });
      }

      dispatch({ type: "SET_LOADING", payload: { constraints: true } });

      loadConstraints(supabase, { batchNumber })
        // .then((constraints) => {
        //   return new Promise<{ material: string; is_claimed: boolean; usage: number; max_limit: number }[]>((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve(constraints);
        //     }, 5000);
        //   });
        // })
        .then((constraints) => {
          dispatch({ type: "SET_CONSTRAINTS", payload: { constraints } });
        })
        .finally(() => {
          dispatch({ type: "SET_LOADING", payload: { constraints: false } });
          toast.current?.show({
            severity: "info",
            summary: "Limity prepočítané!",
            detail: `Načítanie limitov prebehlo úspešne. Rezervácie su znovu k dispozícií.`,
            life: 5000,
          });
        });
    }
  });

  useEffect(() => {
    const loadAllBusinessEntities = async () => {
      dispatch({ type: "SET_LOADING", payload: { constraints: true, usersDealerships: true, vehicles: true } });

      const { dealerships, usersDealerships, vehicles } = await loadVehiclesAndDealerships(supabase, {
        userId,
        batchNumber,
        isAdmin,
      });
      setDealerships(dealerships);
      dispatch({ type: "SET_USERS_DEALERSHIPS", payload: { usersDealerships } });
      dispatch({ type: "SET_VEHICLES", payload: { vehicles } });

      const constraints = await loadConstraints(supabase, { batchNumber });
      dispatch({ type: "SET_CONSTRAINTS", payload: { constraints } });

      dispatch({ type: "SET_LOADING", payload: { constraints: false, usersDealerships: false, vehicles: false } });
      setInitialDataLoaded(true);
    };

    loadAllBusinessEntities().catch(throwError);
  }, [supabase, userId, isAdmin, batchNumber, throwError, dispatch]);

  useEffect(() => {
    if (!initialDataLoaded) return;

    channel.current = supabase
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        // https://supabase.com/docs/guides/realtime/extensions/postgres-changes
        { event: "UPDATE", schema: "public", table: "vehicles", filter: `batch_id=eq.${batchNumber}` },
        onVehicleUpdatedEvent
      )
      .subscribe((state) => {
        console.log(`Channel status changed to ${state}`);
        // todo: if any other state than subscribed is returned, raise an error
      });

    return () => {
      if (channel.current) {
        channel.current.unsubscribe();
      }
    };
  }, [onVehicleUpdatedEvent, batchNumber, supabase, initialDataLoaded]);

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    setFilterColumnValues((_mapping) => {
      let newMapping: { [k: string]: Set<string | null> } = {};
      let finalMapping: { [k: string]: string[] } = {};

      for (const col of columnNames.current) {
        newMapping[col] = new Set();
      }

      for (const vehicle of state.vehicles.data) {
        for (const col of columnNames.current) {
          newMapping[col].add(vehicle[col]);
        }
      }

      for (const col of columnNames.current) {
        const updatedSet = newMapping[col];
        updatedSet.delete(null);
        finalMapping[col] = [...updatedSet] as string[];
      }

      return finalMapping;
    });
  }, [state.vehicles.data]);

  const claimVehicle = async (salesOrder: string, vin: string | null, material: string) => {
    setIsClaiming(true);

    try {
      const claimResponse = await supabase
        .rpc("claim_vehicle", { sales_order: salesOrder })
        .select("material, is_claimed, usage, max_limit");

      // handle error response (internal server error)
      if (claimResponse.error) {
        toast.current?.show({
          severity: "error",
          summary: `Chyba pri rezervácií! (${claimResponse.error.code})`,
          detail: claimResponse.error.details
            ? `${claimResponse.error.message} (${claimResponse.error.details})`
            : claimResponse.error.message,
          life: 7000,
        });
        // TODO: refetch constraints
        return;
      }

      // check whether the claim was successful or not
      const wasSuccess = claimResponse.data.length > 0 && claimResponse.data.some((constr) => constr.is_claimed);
      if (wasSuccess) {
        toast.current?.show({
          severity: "success",
          summary: "Vozidlo úspešne vybraté!",
          detail: `Rezervácia VIN ${vin} (${salesOrder}) bola zaznamenaná.`,
          life: 7000,
        });
      } else {
        const [materialLimit] = claimResponse.data.filter((constr) => constr.material === material);
        toast.current?.show({
          severity: "warn",
          summary: `Limity boli dosiahnuté (${materialLimit.usage}/${materialLimit.max_limit})`,
          detail: `Rezervácia VIN ${vin} (${salesOrder}) nebola úspešná.`,
          life: 7000,
        });
      }

      // update constraints
      dispatch({ type: "SET_CONSTRAINTS", payload: { constraints: claimResponse.data } });
    } finally {
      setIsClaiming(false);
    }
  };

  const resetClaim = async (salesOrder: string) => {
    setIsClaiming(true);

    try {
      const resetResponse = await supabase.rpc("reset_claim", { sales_order: salesOrder });

      // handle error response (internal server error)
      if (resetResponse.error) {
        toast.current?.show({
          severity: "error",
          summary: `Chyba pri rušení rezervácie! (${resetResponse.error.code})`,
          detail: resetResponse.error.details
            ? `${resetResponse.error.message} (${resetResponse.error.details})`
            : resetResponse.error.message,
          life: 7000,
        });
      } else {
        toast.current?.show({
          severity: "success",
          summary: "Rezervácia úspešne zrušená!",
          detail: `Rezervácia ${salesOrder} bola úspešne zrušená. Vozidlo je odteraz dostupné k rezervácií.`,
          life: 7000,
        });
      }
    } finally {
      setIsClaiming(false);
    }
  };

  const claimableVehicles = () => {
    const availableMaterials = new Set(
      state.constraints.data.flatMap((constr) => (constr.usage < constr.max_limit ? [constr.material] : []))
    );
    return state.vehicles.data.flatMap((vehicle) => {
      const isClaimed = !!vehicle["claimed_by"];
      const isMine = vehicle["claimed_by"] === user?.id;
      const isColleagues = colleagueIds.has(vehicle["claimed_by"]);
      return isClaimed || isMine || isColleagues || !availableMaterials.has(vehicle["material"]) ? [] : [vehicle];
    });
  };

  const claimedVehicles = () => {
    return state.vehicles.data.flatMap((vehicle) => {
      const isMine = vehicle["claimed_by"] === user?.id;
      const isColleagues = colleagueIds.has(vehicle["claimed_by"]);
      return isMine || isColleagues ? [vehicle] : [];
    });
  };

  const initFilters = () => {
    setFilters(structuredClone(filtersDefinition.current));
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setFilters((filters) => {
      let _filters = structuredClone(filters);
      (_filters["global"] as DataTableFilterMetaData).value = value;
      return _filters;
    });
    setGlobalFilterValue(value);
  };

  const updateFilter = (column: string, values: string[], mode: "toggle" | "overwrite" | "discardOthers") => {
    setFilters((filters) => {
      let _filters = structuredClone(filters);
      let valueSet = new Set<string>();

      if (mode === "toggle") {
        valueSet = new Set((_filters[column] as DataTableFilterMetaData).value);
        for (const value of values) {
          if (valueSet.has(value)) {
            valueSet.delete(value);
          } else {
            valueSet.add(value);
          }
        }
      } else if (mode === "overwrite" || mode === "discardOthers") {
        valueSet = new Set(values);
      }

      if (mode === "discardOthers") {
        for (const colName in _filters) {
          (_filters[colName] as DataTableFilterMetaData).value = null;
        }
      }

      (_filters[column] as DataTableFilterMetaData).value = [...valueSet];

      return _filters;
    });
  };

  const getFilterValues = (column: string) => {
    if (filters === undefined || filters[column] === undefined) {
      return [];
    }
    const value: string[] | null = (filters[column] as DataTableFilterMetaData).value;

    return value ? value : [];
  };

  const countColumnsFiltered = () => {
    let count = 0;
    for (const column in filters) {
      count += getFilterValues(column).length ? 1 : 0;
    }
    return count;
  };

  const multiselectFilterBuilder = (columnName: string) => {
    return (options: ColumnFilterElementTemplateOptions) => {
      return (
        <MultiSelect
          value={options.value}
          options={filterColumnValues[columnName]}
          onChange={(e: MultiSelectChangeEvent) => {
            // options.filterApplyCallback(e.value);
            updateFilter(columnName, e.value, "overwrite");
          }}
          display="chip"
          scrollHeight="400px"
          filter
          filterPlaceholder="Hľadať"
          placeholder="Vybrať z viacerých hodnôt"
          className="p-column-filter"
        />
      );
    };
  };

  const filterOnlyAvailable = () =>
    updateFilter(
      "sales_order",
      claimableVehicles().map((vehicle) => vehicle["sales_order"]),
      "discardOthers"
    );

  const filterOnlyClaimed = () =>
    updateFilter(
      "sales_order",
      claimedVehicles().map((vehicle) => vehicle["sales_order"]),
      "discardOthers"
    );

  const rowClassName = (data: Record<string, any>) => {
    if (isAdmin && data["dealer"]) {
      return "text-hyundai-800";
    }

    if (!isAdmin && data["claimed_by"] !== null && data["claimed_by"] !== userId && !colleagueIds.has(data["claimed_by"])) {
      return "text-gray-400 cursor-not-allowed";
    }

    return "";
  };

  const claimTemplate = (rowData: Record<string, any>) => {
    const isClaimed = !!rowData["claimed_by"];
    const isMine = rowData["claimed_by"] === user?.id;
    const isClaimedByColleague = isClaimed && colleagueIds.has(rowData["claimed_by"]);
    const [constraint] = state.constraints.data.filter((constr) => constr.material === rowData["material"]);
    const canClaim = constraint && constraint.usage < constraint.max_limit;

    if (isAdmin) {
      const dealer = rowData["dealer"];

      return dealer ? (
        <Button
          className="h-6 text-hyundai-800"
          icon="pi pi-undo"
          severity="info"
          text
          label={`Zrušiť pre ${rowData["dealer"]}`}
          onClick={() => resetClaim(rowData["sales_order"])}
        />
      ) : !dealer && isClaimed ? (
        <Button
          className="h-6 text-hyundai-900"
          icon="pi pi-car"
          severity="secondary"
          disabled
          text
          label={`Teraz rezervované`}
        />
      ) : (
        <Button className="h-6" icon="pi pi-hourglass" severity="secondary" disabled text label={`Čaká na rezerváciu`} />
      );
    }

    if (isMine || isClaimedByColleague) {
      return <Button className="h-6 pointer-events-none" icon="pi pi-check" severity="success" text label="Rezervované" />;
    }

    if (isClaimed) {
      return <Button className="h-6" icon="pi pi-times" severity="secondary" label="Nedostupné" disabled text />;
    }

    if (!canClaim) {
      return (
        <Button
          className="h-6"
          icon="pi pi-times"
          severity="secondary"
          // label={`Limit reached (${constraint.usage}/${constraint.max_limit})`}
          label="Limit dosiahnutý"
          disabled
          text
        />
      );
    }

    return (
      <Button
        className="h-6"
        icon="pi pi-plus"
        // label={`Claim (${constraint.usage}/${constraint.max_limit})`}
        label="Rezervovať"
        disabled={!canClaim}
        text
        onClick={() => claimVehicle(rowData["sales_order"], rowData["vin"], rowData["material"])}
        loading={isClaiming || state.constraints.isLoading}
      />
    );
  };

  const renderHeader = () => {
    return (
      <>
        {!isAdmin && Array.isArray(state.constraints.data) && state.constraints.data.length > 0 && (
          <div className={`bg-hyundai-background w-screen h-[62px] text-xs flex`}>
            <div className="overflow-x-scroll whitespace-nowrap w-screen">
              {state.constraints.data
                .slice(0)
                .sort((a, b) => a.usage / a.max_limit - b.usage / b.max_limit)
                .map((constraint, index) => (
                  <Button
                    type="button"
                    key={`${index}-${constraint}`}
                    // label={`${constraint.material} (${constraint.usage}/${constraint.max_limit})`}
                    label={constraint.material}
                    badge={!state.constraints.isLoading ? constraint.usage + "/" + constraint.max_limit : ""}
                    badgeClassName={`text-white font-semibold text-[0.5rem] ${
                      constraint.usage === constraint.max_limit ? "bg-gray-400" : "bg-hyundai-800"
                    }`}
                    className={`px-2 ${
                      getFilterValues("material").filter((v) => v === constraint.material).length > 0
                        ? "bg-hyundai-highlighted underline"
                        : ""
                    } ${constraint.usage === constraint.max_limit ? "text-gray-400" : "text-hyundai-800"}`}
                    text={true}
                    size="small"
                    onClick={() => updateFilter("material", [constraint.material], "toggle")}
                    loading={state.constraints.isLoading}
                  />
                ))}
            </div>
          </div>
        )}
        {isAdmin && (
          <div className="absolute left-36 top-4 pl-3 z-50">
            <Tag value="Administácia" severity="info" className="bg-hyundai-hover"></Tag>
          </div>
        )}
        <div className="flex justify-between py-2 px-2">
          <div className="flex">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Vyhľadávanie" />
            </span>
            <div>
              <Button
                type="button"
                icon="pi pi-file-export"
                className="text-gray-500 p-overlay-badge overflow-visible"
                visible={!isAdmin}
                severity="secondary"
                tooltip="Exportovať rezervované"
                tooltipOptions={{ position: "bottom" }}
                text
                onClick={() =>
                  exportExcel(
                    claimedVehicles(),
                    Object.fromEntries(columns.filter((col) => col.userExportable).map((col) => [col.field, col.header]))
                  )
                }
              />
            </div>
            {isAdmin && (
              <div>
                <Button
                  type="button"
                  icon="pi pi-file-export"
                  className="text-hyundai-800"
                  severity="secondary"
                  visible={isAdmin}
                  tooltip="Exportovať zostavu ako administrátor"
                  tooltipOptions={{ position: "bottom" }}
                  text
                  onClick={() => {
                    isAdmin &&
                      exportExcel(
                        state.vehicles.data,
                        {
                          sales_order: "Sales.Ord",
                          vin: "VIN",
                          material: "Material",
                          material_description: "Material Description",
                          exterior_color_code: "Ext.color code",
                          interior_color_code: "Int.color code",
                          dealer: "Dealer",
                        },
                        `export_${batchNumber}`
                      );
                  }}
                />
              </div>
            )}
            {isAdmin && (
              <div>
                <Button
                  type="button"
                  icon="pi pi-chart-bar"
                  className="text-hyundai-800"
                  severity="secondary"
                  visible={isAdmin}
                  tooltip="Zobraziť štatistiky o rezerváciach"
                  tooltipOptions={{ position: "bottom" }}
                  text
                  onClick={() => setShowAdminStatistics(true)}
                />
              </div>
            )}
          </div>
          <div className="flex">
            <div>
              <Button
                type="button"
                icon="pi pi-check"
                className="text-green-800 p-overlay-badge overflow-visible w-16"
                visible={!isAdmin}
                tooltip="Filtrovať iba rezervované (s)"
                tooltipOptions={{ position: "left" }}
                text
                onClick={filterOnlyClaimed}
                badge={`${claimedVehicles().length}/${state.constraints.data
                  .map((constr) => constr.max_limit)
                  .reduce((prev, curr) => prev + curr, 0)}`}
                badgeClassName="translate-x-0 translate-y-0 bg-transparent text-green-800"
              />
            </div>
            <div>
              <Button
                type="button"
                icon="pi pi-car"
                className="text-hyundai-800 p-overlay-badge overflow-visible w-16"
                visible={!isAdmin}
                tooltip={
                  "Filtrovať iba dostupné (d)\n\nFormát:\n<všetky dostupné vozidlá vrámci limitov> (<počet volných rezervácii>)"
                }
                tooltipOptions={{ position: "left" }}
                text
                onClick={filterOnlyAvailable}
                badge={`${claimableVehicles().length} (${state.constraints.data
                  .map((constr) => constr.max_limit - constr.usage)
                  .reduce((prev, curr) => prev + curr, 0)})`}
                badgeClassName="translate-x-0 translate-y-0 bg-transparent text-hyundai-800"
              />
            </div>
            <div>
              <Button
                type="button"
                icon="pi pi-filter-slash"
                className="text-red-600 p-overlay-badge overflow-visible"
                severity="danger"
                tooltip="Zrušiť všetky filtre (r)"
                tooltipOptions={{ position: "left" }}
                text
                onClick={initFilters}
                badge={countColumnsFiltered().toString()}
                badgeClassName="translate-x-0 translate-y-0 bg-transparent text-red-600"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  if (state.vehicles.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Toast ref={toast} position="top-left" />

      <Dialog
        header={
          <div>
            <h1 className="text-lg font-bold">Štatistiky</h1>
          </div>
        }
        maximizable
        draggable={false}
        modal={true}
        visible={showAdminStatistics}
        style={{ width: "90vw", height: "85vh" }}
        onHide={() => setShowAdminStatistics(false)}
      >
        <Suspense fallback={<Loader />}>
          <BatchStatistics batchNumber={batchNumber} />
        </Suspense>
      </Dialog>

      {/* Vehicles listing (table) */}
      <div className={`text-xs min-h-full pt-1`}>
        <DataTable
          header={renderHeader()}
          // tableStyle={{ minWidth: "calc(100vw)" }}
          className="w-screen overflow-x-hidden"
          scrollHeight={`calc(100vh - 64px - 48px ${
            !isAdmin && Array.isArray(state.constraints.data) && state.constraints.data.length > 0 ? "- 62px" : ""
          })`}
          loading={state.vehicles.isLoading}
          size="small"
          value={state.vehicles.data}
          dataKey="sales_order"
          showGridlines
          scrollable
          stripedRows
          rowHover
          // paginator
          // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          // rows={25}
          // rowsPerPageOptions={[5, 10, 25, 50, 100]}
          sortMode="multiple"
          removableSort
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={columns.map((col) => col.field)}
          resizableColumns
          stateStorage="local"
          stateKey="hyundai-drs-local"
          rowClassName={rowClassName}
          selectionMode={undefined}
          emptyMessage="Žiadne výsledky"
        >
          {columns.map((col, i) => (
            <Column
              key={col.field}
              header={col.header}
              field={col.field}
              filter={col.filterable}
              filterField={col.field}
              {...(col.filterFactory
                ? {
                    showFilterMatchModes: false,
                    filterElement: col.filterFactory(),
                    filterMenuStyle: { width: "25rem" },
                    showApplyButton: false,
                    showClearButton: true,
                    filterClear: (options: ColumnFilterClearTemplateOptions) => {
                      return (
                        <Button
                          type="button"
                          icon="pi pi-times"
                          onClick={options.filterClearCallback}
                          severity="danger"
                          outlined
                          label="Odstrániť výber z filtra"
                        ></Button>
                      );
                    },
                    onFilterClear: () => {
                      updateFilter(col.field, [], "overwrite");
                    },
                  }
                : {})}
              sortable={col.sortable}
              body={col.templateFactory ? col.templateFactory() : null}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default VehiclesListingPage;
