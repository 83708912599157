import { SupabaseClient } from "@supabase/supabase-js";
import { supabaseRetry } from "../../libs/supabase";
import { extractColleagueIds } from "./logic";

export async function loadVehiclesAndDealerships(
  supabase: SupabaseClient,
  params: { userId: string | undefined; batchNumber: number; isAdmin: boolean }
): Promise<{
  dealerships: { [dealer_no: string]: { dealerName: string; userIds: string[] } };
  usersDealerships: { [user_id: string]: { dealerNo: string } };
  vehicles: Record<string, any>[];
}> {
  const { data } = await supabaseRetry(
    supabase,
    async (supabase) => await supabase.rpc("users_in_dealership").select("dealer_no, dealer_name, user_ids")
  );

  const dealerships: { [dealer_no: string]: { dealerName: string; userIds: string[] } } = Object.fromEntries(
    data?.map((row) => [row.dealer_no, { dealerName: row.dealer_name, userIds: row.user_ids }]) ?? []
  );

  const usersDealerships = Object.fromEntries(
    Object.entries(dealerships)
      .map(([dealerNo, spec]) => spec.userIds.map((userId) => [userId, { dealerNo }]))
      .flat()
  );

  let claimFilters = params.userId ? ["claimed_by.is.null", `claimed_by.eq.${params.userId}`] : ["claimed_by.is.null"];
  claimFilters = claimFilters.concat(
    [...extractColleagueIds(usersDealerships, dealerships, params.userId)].map((id) => `claimed_by.eq.${id}`)
  );

  let vehicles = [];

  if (params.isAdmin) {
    const { data: vehicleData } = await supabaseRetry(
      supabase,
      async (supabase) =>
        await supabase
          .rpc("admin_export_vehicles_in_batch", { batch_nr: params.batchNumber })
          .select("sales_order,vin,material,material_description,exterior_color_code,interior_color_code,dealer")
          .order("sales_order", { ascending: true })
          .throwOnError()
    );
    vehicles = vehicleData!;
  } else {
    const { data: vehicleData } = await supabaseRetry(
      supabase,
      async (supabase) =>
        await supabase
          .from("vehicles")
          .select("vin,sales_order,claimed_by,material,material_description,exterior_color_code,interior_color_code")
          .filter("batch_id", "eq", params.batchNumber)
          .or(claimFilters.join(","))
          .order("sales_order", { ascending: true })
          .throwOnError()
    );
    vehicles = vehicleData!;
  }

  return {
    dealerships,
    usersDealerships,
    vehicles,
  };
}

export async function loadConstraints(
  supabase: SupabaseClient,
  params: { batchNumber: number }
): Promise<{ material: string; is_claimed: boolean; usage: number; max_limit: number }[]> {
  const { data: claimData } = await supabaseRetry(
    supabase,
    async (supabase) =>
      await supabase.rpc("claim_usage", { batch_nr: params.batchNumber }).select("material, is_claimed, usage, max_limit")
  );

  return claimData ? claimData! : [];
}
