export function extractColleagueIds(
  usersDealerships: { [user_id: string]: { dealerNo: string } },
  dealerships: { [dealer_no: string]: { dealerName: string; userIds: string[] } },
  userId: string | undefined
): Set<string> {
  if (!usersDealerships[userId ?? ""]) {
    return new Set();
  }
  const extractedUsers = userId ? dealerships[usersDealerships[userId].dealerNo].userIds : [];
  let colleagueIds = new Set(extractedUsers);
  colleagueIds.delete(userId ?? "");

  return colleagueIds;
}
