import { useCallback, useInsertionEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

type CallbackType<A extends any[], R> = (...args: A) => R;

export const useAsyncError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState();

  return useCallback(
    (e: Error) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

export const useEvent = <A extends any[], R>(callback: CallbackType<A, R>): CallbackType<A, R> => {
  // https://blog.bitsrc.io/a-look-inside-the-useevent-polyfill-from-the-new-react-docs-d1c4739e8072
  const functionRef = useRef(callback);

  useInsertionEffect(() => {
    functionRef.current = callback;
  });

  return useCallback((...args) => {
    const functionCall = functionRef.current;
    return functionCall(...args);
  }, []);
};

export const useBatchNumber = () => {
  const { batchId } = useParams();
  const batchNumber = parseInt(batchId!, 10);

  return batchNumber;
};
