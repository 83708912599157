import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { Auth as SupabaseAuth } from "@supabase/auth-ui-react";
import { ThemeSupa, ViewType } from "@supabase/auth-ui-shared";

export type { ViewType } from "@supabase/auth-ui-shared";

export default function Auth(props: { view: ViewType }) {
  const supabase = useSupabaseClient();

  return (
    <SupabaseAuth
      supabaseClient={supabase}
      appearance={{
        theme: ThemeSupa,
        variables: { default: { colors: { brand: "rgb(17, 42, 93)", brandAccent: "rgb(23, 69, 132)" } } },
      }}
      providers={[]}
      showLinks={false}
      view={props.view}
      localization={{
        variables: {
          sign_in: {
            email_label: "Emailová adresa",
            password_label: "Heslo",
            email_input_placeholder: "Zadajte vašu emailovú adresa",
            password_input_placeholder: "Zadajte vaše heslo",
            button_label: "Prihlásiť sa",
            loading_button_label: "Prihlasovanie prebieha ...",
            social_provider_text: "Prihlásiť sa s {{provider}}",
            link_text: "Už máte účet? Prihlásiť sa",
          },
          forgotten_password: {
            email_label: "Emailová adresa",
            password_label: "Heslo",
            email_input_placeholder: "Zadajte vašu emailovú addresu",
            button_label: "Odoslať inštrukcie na zmenu heslo",
            loading_button_label: "Odosielanie prebieha ...",
            link_text: "Zabudnuté heslo",
            confirmation_text: "Skontrolujte si emailovú schránku, príde vám odkaz na obnovu hesla.",
          },
          update_password: {
            password_label: "Nové heslo",
            password_input_placeholder: "Zadajte nové heslo",
            button_label: "Zmeniť heslo",
            loading_button_label: "Prebieha zmena hesla...",
            confirmation_text: "Vaše heslo bolo zmenené",
          },
        },
      }}
    />
  );
}
