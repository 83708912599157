import { useEffect, useState, Fragment, useRef } from "react";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { Link, NavLink } from "react-router-dom";

import Loader from "./Loader";
import Auth from "./Auth";
import { ReactComponent as Logo } from "../logo.svg";

const Navigation = () => {
  const user = useUser();
  const supabase = useSupabaseClient();

  const [dealership, setDealership] = useState("-");
  const [isLoading, setIsLoading] = useState(true);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const toast = useRef<Toast>(null);

  let navigation = [
    { name: "Domov", href: "/", disabled: false },
    { name: "Zoznam vozidiel", href: "/vehicles", disabled: true },
  ];
  if (user?.app_metadata.hyundairds_is_admin) {
    navigation.push({
      name: "Nahrať zostavu",
      href: "/upload",
      disabled: false,
    });
  }

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event) => {
      // https://supabase.com/docs/reference/javascript/auth-resetpasswordforemail
      console.log("Auth event", event);

      if (event === "USER_UPDATED") {
        setShowPasswordReset(false);
        toast.current?.show({
          severity: "success",
          summary: "Heslo zmené!",
          detail: "Heslo bolo úspešne zmenené",
          life: 10000,
        });
      } else if (event === "PASSWORD_RECOVERY") {
        setShowPasswordReset(true);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);

      const dealershipResult = await supabase.from("dealers").select("display_name, dealer_no").limit(1).maybeSingle();
      if (!dealershipResult.data) {
        console.warn("No dealership found");
      }
      setDealership(dealershipResult.data?.display_name ? dealershipResult.data?.display_name : dealershipResult.data?.dealer_no);

      setIsLoading(false);
    };

    initialize();
  }, [supabase]);

  const signOut = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = await supabase.auth.signOut();
    // TODO: error handling
  };

  const avatarInitials = () => {
    try {
      return user?.email
        ?.split("@")[0]
        .split(".")
        .slice(0, 2)
        .map((part) => part.charAt(0).toUpperCase())
        .join("");
    } catch (error) {
      console.error(`Error generating avatar for user ${user?.email}`);
      return "?";
    }
  };

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
  };

  if (isLoading) {
    return (
      <div className="h-12">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <Dialog
        header="Zmena hesla"
        visible={showPasswordReset}
        style={{ width: "50vw" }}
        onHide={() => setShowPasswordReset(false)}
      >
        <Auth view="update_password" />
      </Dialog>
      <Disclosure as="nav" className="bg-white flex-none shadow-menu">
        {({ open }) => (
          <>
            <div className="mx-auto">
              <div className="relative flex items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden bg-hyundai">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-hyundai-menu-hover hover:text-hyundai-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Otvoriť hlavné menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center sm:items-stretch sm:justify-start justify-center sm:bg-transparent bg-hyundai">
                  <div className="flex flex-shrink-0 items-center justify-center bg-hyundai h-12 w-16">
                    <Link to="/" className="text-white">
                      <Logo className="block w-10 lg:hidden" />
                      <Logo className="hidden w-10 lg:block" />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4 h-12 py-0">
                      {navigation.map((item) => (
                        <NavLink
                          to={item.href}
                          key={`wrapper-${item.name}`}
                          className={({ isActive, isPending }) =>
                            classNames(
                              isActive
                                ? "border-hyundai-800 text-hyundai"
                                : isPending
                                ? "border-hyundai-700 text-hyundai"
                                : "text-gray-500 hover:border-hyundai-800 hover:text-gray-800 border-transparent",
                              item.disabled ? "pointer-events-none" : "",
                              "rounded-none border-b-2 px-3 text-sm font-medium flex justify-center items-center"
                            )
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-0 focus:ring-offset-white">
                        <span className="sr-only">Otvoriť uživatelské menu</span>
                        <div
                          className={classNames(
                            "relative inline-flex items-center justify-center w-8 h-8 border-2 rounded-full",
                            dealership
                              ? "border-hyundai sm:border-gray-300 bg-hyundai-background sm:bg-white"
                              : "border-yellow-500 bg-yellow-100 animate-pulse"
                          )}
                        >
                          {!dealership && (
                            <span className="absolute top-0 right-0 text-xs translate-x-1/3 -translate-y-1/3">⚠️</span>
                          )}
                          <span className="font-medium text-gray-600">{avatarInitials()}</span>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <div className={classNames(active ? "bg-gray-100" : "", "w-full px-4 py-2 text-sm text-gray-700")}>
                              {user?.email}
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                !dealership ? "text-yellow-500 font-semibold" : "",
                                "w-full px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {dealership ?? "⚠️ Užívateľ nemá priradené číslo dealera"}
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setShowPasswordReset(true);
                              }}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "w-full px-4 py-2 text-sm font-semibold text-gray-700"
                              )}
                            >
                              Zmeniť heslo
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={signOut}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "w-full px-4 py-2 text-sm font-semibold text-gray-700"
                              )}
                            >
                              Odhlásiť sa
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <NavLink
                    to={item.href}
                    key={`mobilewrapper-${item.name}`}
                    className={({ isActive, isPending }) =>
                      classNames(
                        isActive
                          ? "bg-hyundai-900 text-white"
                          : isPending
                          ? "bg-gray-900 text-white"
                          : "text-hyundai hover:bg-hyundai hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )
                    }
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Navigation;
