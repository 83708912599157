import { createClient, SupabaseClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl!, supabaseAnonKey!);

export async function supabaseRetry<T>(client: SupabaseClient, fn: (client: SupabaseClient) => Promise<T>): Promise<T> {
  const shouldRetryFn = async (error: any, attempt: number) => {
    if (attempt >= 3) {
      return Promise.resolve(false);
    }

    if (error.code === "PGRST301") {
      console.log("refreshing token due to", error, attempt);
      await supabase.auth.refreshSession();
      return true;
    }

    return Promise.resolve(false);
  };

  for (let retryAttempt = 0; retryAttempt <= 3; retryAttempt++) {
    try {
      const result = await fn(client);

      return result;
    } catch (error) {
      const shouldRetry = await shouldRetryFn(error, retryAttempt + 1);

      if (shouldRetry) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        continue;
      } else {
        throw error;
      }
    }
  }

  throw new Error("Maximum limit of retries reached");
}
