import { useCallback, useEffect, useState } from "react";

import { Tab } from "@headlessui/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import Loader from "../components/Loader";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function HomePage() {
  const [batches, setBatches] = useState<Record<string, any>[]>([]);
  const [categories] = useState(["Otvorené", "Pripravované", "Všetky"]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const navigate = useNavigate();
  const { hash } = useLocation();

  const supabase = useSupabaseClient();
  const user = useUser();
  const isAdmin = user?.app_metadata.hyundairds_is_admin;

  const loadBatches = useCallback(async () => {
    setIsLoading(true);
    const { data } = await supabase
      .from("batches")
      .select("id, start_at, end_at, created_at, display_name, vehicles(count)")
      .order("start_at", { ascending: false })
      .throwOnError();
    // .filter("start_at", "gte", new Date().toISOString());
    setBatches(data!);
    setIsLoading(false);
  }, [supabase]);

  const deleteBatch = useCallback(
    async (batchNumber: number) => {
      setIsLoading(true);

      await supabase.rpc("admin_delete_batch", { batch_nr: batchNumber });
      await loadBatches();

      setIsLoading(false);
    },
    [supabase, loadBatches]
  );

  useEffect(() => {
    loadBatches();
  }, [loadBatches]);

  useEffect(() => {
    const category = decodeURIComponent(hash.slice(1));
    const idx = categories.indexOf(category);
    setSelectedTabIndex(idx === -1 ? 0 : idx);
  }, [categories, hash]);

  return (
    <div className="flex flex-col items-center justify-start px-2 pt-4 space-x-8 w-screen">
      <div className="min-w-full px-0">
        <Tab.Group selectedIndex={selectedTabIndex} onChange={(idx) => navigate(`#${encodeURIComponent(categories[idx])}`)}>
          <Tab.List className="flex space-x-1 rounded-xl bg-hyundai-900/10 p-1">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5 ",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-hyundai focus:outline-none focus:ring-2",
                    selected ? "bg-white text-hyundai" : "text-hyundai-900/80 hover:bg-white/[0.6] hover:text-hyundai"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {isLoading && (
              <div className="pt-4">
                <Loader />
              </div>
            )}
            {!isLoading &&
              categories.map((category, idx) => (
                <Tab.Panel key={idx} className={classNames("rounded-xl bg-white border-gray-200 border")}>
                  <ul className="divide-gray-200 divide-y py-0">
                    {batches
                      .filter((batch) =>
                        category === "Pripravované"
                          ? batch["start_at"] > new Date().toISOString()
                          : category === "Otvorené"
                          ? batch["start_at"] < new Date().toISOString() && new Date().toISOString() < batch["end_at"]
                          : category === "Všetky"
                          ? true
                          : false
                      )
                      .map((batch) => (
                        <li key={batch.id} className="relative p-4">
                          <h3 className="text-md font-semibold leading-5">
                            {/* Batch name */}
                            <NavLink to={`/vehicles/${batch.id}`}>{batch.display_name || `#${batch.id}`}</NavLink> &nbsp;
                            {/* Batch label */}
                            {batch["start_at"] > new Date().toISOString() ? (
                              <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-1.5 py-0.5 rounded">
                                Pripravované
                              </span>
                            ) : batch["start_at"] < new Date().toISOString() && new Date().toISOString() < batch["end_at"] ? (
                              <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-1.5 py-0.5 rounded">
                                Otvorené
                              </span>
                            ) : (
                              <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-1.5 py-0.5 rounded">
                                Ukončené
                              </span>
                            )}
                          </h3>

                          <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-400">
                            <li>
                              {batch["start_at"] > new Date().toISOString()
                                ? `otvára sa ${new Date(batch.start_at).toLocaleString()}`
                                : batch["start_at"] < new Date().toISOString() && new Date().toISOString() < batch["end_at"]
                                ? `zatvára sa ${new Date(batch.end_at).toLocaleString()}`
                                : `zatvorené od ${new Date(batch.end_at).toLocaleString()}`}
                            </li>
                          </ul>

                          <div className="pt-1 font-normal">
                            <NavLink to={`/vehicles/${batch.id}`}>
                              {batch.vehicles[0].count || 0}{" "}
                              {(batch.vehicles[0].count || 0) === 1
                                ? "vozidlo"
                                : (batch.vehicles[0].count || 0) <= 4 && (batch.vehicles[0].count || 0) >= 1
                                ? "vozidlá"
                                : "vozidiel"}
                            </NavLink>
                            {/* Admin actions */}
                            {isAdmin && (
                              <span className="text-red-700 text-xs cursor-pointer pl-3" onClick={() => deleteBatch(batch.id)}>
                                <i className="pi pi-trash text-xs" /> Zmazať
                              </span>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
