import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Dialog } from "primereact/dialog";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Steps } from "primereact/steps";
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem";

import { useCallback, useRef, useState } from "react";
import { read, utils, WorkSheet } from "xlsx";
import { z } from "zod";
import { supabase } from "../libs/supabase";

const VehicleSchema = z
  .object({
    "Sales.Ord": z.number().transform((num) => num?.toString()),
    VIN: z.string().nullish(),
    Material: z.string(),
    "Material Description": z.string(),
    "Ext.color code": z.string(),
    "Int.color code": z.string(),
  })
  .transform((input) => ({
    salesOrder: input["Sales.Ord"],
    vin: input.VIN,
    material: input.Material,
    materialDescription: input["Material Description"],
    exteriorColorCode: input["Ext.color code"],
    interiorColorCode: input["Int.color code"],
  }));

const ConstraintSchema = z
  .object({
    Dealer: z.string(),
    Material: z.string(),
    Limit: z.number().positive(),
  })
  .transform((input) => ({
    dealer: input.Dealer,
    material: input.Material,
    limit: input.Limit,
  }));

type VehicleData = z.output<typeof VehicleSchema>;
type VehicleInput = z.input<typeof VehicleSchema>;
type ConstraintData = z.output<typeof ConstraintSchema>;
type ConstraintInput = z.input<typeof ConstraintSchema>;
type ParserAccumulator<T, E> = { outputs: T[]; errors: z.ZodError<E>[] };

enum WizardStep {
  SelectFile = "Vybrať súbor",
  SelectName = "Pomenovať zostavu",
  SelectRange = "Vybrať obdobie",
  Confirm = "Potvrdenie",
}

function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

function processSheet<In, Out>(worksheet: WorkSheet, schema: z.ZodTypeAny): ParserAccumulator<Out, In> {
  let parsed = utils.sheet_to_json<In>(worksheet).reduce(
    (accumulator: ParserAccumulator<Out, In>, row: In, index) => {
      const result = schema.safeParse(row);
      if (result.success) {
        accumulator.outputs.push(result.data);
      } else {
        const column = result.error.errors[0].path[0];
        const rawError = result.error.errors[0] as unknown as Record<string, string[]>;
        result.error.issues = [];
        result.error.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Chyba na riadku ${index + 1 + 1} (stĺpec ${column}), hodnota: ${rawError["received"]}`,
          path: [column],
        });
        accumulator.errors.push(result.error);
      }
      return accumulator;
    },
    { outputs: [], errors: [] }
  );
  return parsed;
}

export default function UploadPage() {
  const [vehicles, setVehicles] = useState<VehicleData[]>([]);
  const [constraints, setConstraints] = useState<ConstraintData[]>([]);
  const [errors, setErrors] = useState<z.ZodError<VehicleInput | ConstraintInput>[]>([]);
  const [dates, setDates] = useState<Date[] | null>(null);
  const [name, setName] = useState("");

  const [dialogMode, setDialogMode] = useState<null | "errors" | "vehicles" | "constraints">(null);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [wizardStep, setWizardStep] = useState<WizardStep>(WizardStep.SelectFile);

  const toast = useRef<Toast>(null);

  const menuItems: MenuItem[] = [
    {
      label: WizardStep.SelectFile,
      command: (event: MenuItemCommandEvent) => {
        setWizardStep(event.item.label as WizardStep);
      },
    },
    {
      label: WizardStep.SelectName,
      command: (event: MenuItemCommandEvent) => {
        setWizardStep(event.item.label as WizardStep);
      },
    },
    {
      label: WizardStep.SelectRange,
      command: (event: MenuItemCommandEvent) => {
        setWizardStep(event.item.label as WizardStep);
      },
    },
    {
      label: WizardStep.Confirm,
      command: (event: MenuItemCommandEvent) => {
        setWizardStep(event.item.label as WizardStep);
      },
    },
  ];

  const fileHandler = useCallback(
    async (event: FileUploadHandlerEvent) => {
      const rawFile = await readFileAsync(event.files[0]);
      const workbook = read(rawFile, { cellHTML: false, cellStyles: false });

      // ensure "list" and "split" sheets exist
      const vehicleSheet = workbook.Sheets["list"];
      let constraintSheet = workbook.Sheets["split"];

      // process vehicles
      const { outputs: vehicleOutputs, errors: vehicleErrors } = processSheet<VehicleInput, VehicleData>(
        vehicleSheet,
        VehicleSchema
      );
      setVehicles(vehicleOutputs);

      // process constraints
      constraintSheet["!merges"]?.forEach((merge) => {
        // "ungroup" merged cells into each row
        const value = utils.encode_range(merge).split(":")[0];
        for (let col = merge.s.c; col <= merge.e.c; col++)
          for (let row = merge.s.r; row <= merge.e.r; row++)
            constraintSheet[String.fromCharCode(65 + col) + (row + 1)] = constraintSheet[value];
      });
      const { outputs: constraintOutput, errors: constraintErrors } = processSheet<ConstraintInput, ConstraintData>(
        constraintSheet,
        ConstraintSchema
      );
      setConstraints(constraintOutput);

      // merge errors
      setErrors([...vehicleErrors, ...constraintErrors]);

      if (errors.length > 1) {
        toast.current?.show({
          severity: "warn",
          summary: "Chyby v súbore!",
          detail: "Nahraný súbor bol úspešne spracovaný, avšak obsahuje chybné riadky. Skontrolujte prosím výsledky.",
          life: 10000,
        });
      } else {
        toast.current?.show({
          severity: "info",
          summary: "Súbor pripravený na spracovanie!",
          detail: "Nahraný súbor bol úspešne spracovaný. Skontrolujte prosím výsledky pred odoslaním.",
          life: 10000,
        });
      }

      setActiveIndex((index) => 1);
      setWizardStep(WizardStep.SelectName);
    },
    [errors]
  );

  const startUpload = async (vehicles: VehicleData[], constraints: ConstraintData[], dates: Date[], name: string) => {
    setIsUploadInProgress(true);
    try {
      const ingestResponse = await supabase.functions.invoke("api/ingest", {
        method: "POST",
        body: {
          vehicles,
          constraints,
          startAt: dates[0].toISOString(),
          endAt: dates[1].toISOString(),
          name,
        },
      });

      if (ingestResponse.error) {
        toast.current?.show({
          severity: "error",
          summary: "Chyby v spracovaní!",
          detail: `Nahraný súbor nebol úspešne spracovaný z dôvodu: ${ingestResponse.error}`,
          life: 30000,
        });
      } else if (ingestResponse.data) {
        toast.current?.show({
          severity: "success",
          summary: "Spracovanie prebehlo úspešne!",
          detail: `Nahraný súbor bol úspešne prijatý ako zostava ${ingestResponse.data.batch.id}.`,
          life: 30000,
        });
      }
    } finally {
      setIsUploadInProgress(false);
    }
  };

  const triggerConfirmation = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Ste si istý, že chcete pokračovať v nahraní zostavy?",
        acceptLabel: "Áno",
        rejectLabel: "Nie",
        icon: "pi pi-exclamation-triangle",
        accept: () => startUpload(vehicles, constraints, dates!, name),
        reject: () => {},
      });
    },
    [vehicles, constraints, dates, name]
  );

  const assignDateRange = useCallback((event: CalendarChangeEvent) => {
    const value = event.value!;
    if (Array.isArray(value)) {
      setDates(value);
    }
  }, []);

  const anyResultsProcessed = useCallback(
    () => errors.length > 0 || constraints.length > 0 || vehicles.length > 0,
    [vehicles, constraints, errors]
  );

  const areDatesValid = useCallback(
    () => Array.isArray(dates) && dates.length === 2 && dates[0] instanceof Date && dates[1] instanceof Date,
    [dates]
  );

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <Dialog visible={dialogMode ? true : false} style={{ width: "80vw" }} maximizable onHide={() => setDialogMode(null)}>
        {dialogMode === "vehicles" ? (
          <div>
            <pre>{JSON.stringify(vehicles, undefined, 2)}</pre>
          </div>
        ) : dialogMode === "constraints" ? (
          <div>
            <pre>{JSON.stringify(constraints, undefined, 2)}</pre>
          </div>
        ) : dialogMode === "errors" ? (
          <div>
            <pre>{JSON.stringify(errors, undefined, 2)}</pre>
          </div>
        ) : null}
      </Dialog>
      <ConfirmPopup />
      <div className="w-screen p-3">
        <Steps model={menuItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
        <div className="mt-4 pt-12 border-t-2 border-t-gray-200 flex items-center justify-center">
          {wizardStep === WizardStep.SelectFile ? (
            // Select file
            <FileUpload
              mode="basic"
              name="upload[]"
              chooseLabel="Nahrať"
              customUpload
              auto
              uploadHandler={fileHandler}
              disabled={anyResultsProcessed()}
            />
          ) : wizardStep === WizardStep.SelectName ? (
            // Select name
            <div className="flex items-end">
              <div className="flex flex-col gap-2">
                <label htmlFor="batch-name">Meno zostavy</label>
                <InputText
                  id="batch-name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setActiveIndex(2);
                      setWizardStep(WizardStep.SelectRange);
                    }
                  }}
                  placeholder="Pomenovať zostavu"
                />
              </div>
              <div>
                <Button
                  label="Ďalej"
                  icon="pi pi-arrow-right"
                  className="ml-4"
                  iconPos="right"
                  severity="info"
                  onClick={() => {
                    setActiveIndex(2);
                    setWizardStep(WizardStep.SelectRange);
                  }}
                  disabled={name === ""}
                />
              </div>
            </div>
          ) : wizardStep === WizardStep.SelectRange ? (
            // Select range
            <div className="flex items-end">
              <div className="flex flex-col gap-2 w-[40vw]">
                <label htmlFor="batch-dates">Obdobie zostavy</label>
                <Calendar
                  id="batch-dates"
                  value={dates}
                  onChange={assignDateRange}
                  placeholder="Vybrať obdobie"
                  selectionMode="range"
                  readOnlyInput
                  showTime
                  hourFormat="24"
                />
              </div>
              <div>
                <Button
                  label="Ďalej"
                  icon="pi pi-arrow-right"
                  className="ml-4"
                  iconPos="right"
                  severity="info"
                  onClick={() => {
                    setActiveIndex(3);
                    setWizardStep(WizardStep.Confirm);
                  }}
                  disabled={!areDatesValid()}
                />
              </div>
            </div>
          ) : wizardStep === WizardStep.Confirm ? (
            <div className="flex-cols space-y-3">
              <div className="flex flex-col justify-center text-center gap-2">
                <div className="text-lg">
                  Zostava <span className="font-semibold">{name}</span>
                </div>
                <div>
                  {dates?.at(0)?.toLocaleString()} - {dates?.at(1)?.toLocaleString()}
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  type="button"
                  label="Vozidlá"
                  text
                  badge={vehicles.length.toString()}
                  badgeClassName="p-badge-success"
                  severity="success"
                  visible={vehicles.length > 0}
                  onClick={() => setDialogMode("vehicles")}
                />
                <Button
                  type="button"
                  label="Limity"
                  text
                  badge={constraints.length.toString()}
                  badgeClassName="p-badge-success"
                  severity="success"
                  visible={vehicles.length > 0}
                  onClick={() => setDialogMode("constraints")}
                />
                <Button
                  type="button"
                  label="Chyby"
                  text
                  badge={errors.length.toString()}
                  badgeClassName="p-badge-warning"
                  severity="warning"
                  visible={errors.length > 0}
                  onClick={() => setDialogMode("errors")}
                />
              </div>
              <div className="flex space-x-6 justify-center">
                <Button
                  label="Odoslať"
                  icon="pi pi-send"
                  iconPos="left"
                  severity="info"
                  onClick={triggerConfirmation}
                  loading={isUploadInProgress}
                  disabled={!areDatesValid() || errors.length > 0}
                  visible={anyResultsProcessed()}
                  tooltip={
                    "⚠️ Pred odoslaním skontrolujte zoznam vozidiel a limitov.\n\n🗓 Následne vyberte obdobie od kedy do kedy budú vozidlá k dispozícií."
                  }
                  tooltipOptions={{ showOnDisabled: true, position: "bottom" }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
