import { RealtimePostgresUpdatePayload } from "@supabase/supabase-js";

export function vehicleUpdate(
  payload: RealtimePostgresUpdatePayload<{ [key: string]: any }>,
  data: {
    vehicles: Record<string, any>[];
    usersDealerships: { [user_id: string]: { dealerNo: string } };
  },
  props: {
    isAdmin: boolean;
    userId: string | undefined;
    colleagueIds: Set<string>;
  }
): { vehicles: Record<string, any>[]; refreshConstraints: boolean } {
  let doConstraintsNeedRefresh = false;
  let displayedSalesOrders = new Set<string>();

  let updatedVehicles = data.vehicles.map((originalVehicle) => {
    let vehicle = Object.assign({}, originalVehicle);
    displayedSalesOrders.add(vehicle["sales_order"]);

    if (vehicle["sales_order"] === payload.new["sales_order"]) {
      // only update the "claimant" as the spec / other attributes shouldn't change
      const oldClaimedBy = vehicle["claimed_by"];
      vehicle["claimed_by"] = payload.new["claimed_by"];

      // handle changes in admin view (where column "claimed_by" is missing in the source data, but not in events)
      if (props.isAdmin) {
        // "claimant" was reset, also reset the dealer or fill out the dealership automatically by looking up user's dealership
        vehicle["dealer"] = payload.new["claimed_by"] === null ? null : data.usersDealerships[payload.new["claimed_by"]].dealerNo;
      }
      // handle changes in regular view
      else {
        // [old: null, new: any user] -> someone claimed, if our colleagues, refresh
        //                               (if it was us the claims are returned and updated directly when the action was executed)
        const isClaimAffectingUs = oldClaimedBy === null && props.colleagueIds.has(payload.new["claimed_by"]);
        // [old: any user, new: null] -> admin reset someone's claim, if the user was either us or our colleague, refresh
        const isAdminsResetAffectingUs =
          (props.colleagueIds.has(oldClaimedBy) || oldClaimedBy === props.userId) && payload.new["claimed_by"] === null;

        doConstraintsNeedRefresh = isClaimAffectingUs || isAdminsResetAffectingUs ? true : doConstraintsNeedRefresh;
      }
    }

    return vehicle;
  });

  // the vehicle is not displayed in the table and is unclaimed => show it to the user
  // (we initially load all unclaimed and our vehicles, so if an admin resets the claimed we need to display it to the user)
  if (!displayedSalesOrders.has(payload.new["sales_order"]) && payload.new["claimed_by"] === null) {
    updatedVehicles = [...updatedVehicles, payload.new];
  }

  return { vehicles: updatedVehicles, refreshConstraints: doConstraintsNeedRefresh };
}
