import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";

import App from "./App";

import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import UploadPage from "./pages/UploadPage";
import VehiclesListingPage from "./pages/VehicleListing/VehiclesListingPage";

import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { supabase } from "./libs/supabase";

import { enableMapSet } from "immer";

enableMapSet();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/vehicles",
        element: <HomePage />,
      },
      {
        path: "/vehicles/:batchId",
        element: <VehiclesListingPage />,
      },
      {
        path: "/upload",
        element: <UploadPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <SessionContextProvider supabaseClient={supabase}>
      <RouterProvider router={router} />
    </SessionContextProvider>
  </React.StrictMode>
);
