import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "./App.css";

import { useSessionContext } from "@supabase/auth-helpers-react";

import { useState } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "./components/Navigation";
import Loader from "./components/Loader";
import Auth, { ViewType } from "./components/Auth";

import { ReactComponent as Logo } from "./logo.svg";

const App = () => {
  const { session, isLoading } = useSessionContext();
  const [authView, setAuthView] = useState<ViewType>("sign_in");

  if (isLoading) {
    return <Loader />;
  }

  return !session ? (
    // Login screen
    <div className="flex items-center justify-center h-full">
      <div className="relative flex flex-col items-center justify-center">
        <div className="flex flex-row space-x-2 w-full h-8 items-center justify-center mt-6 text-hyundai">
          <Logo className="block h-6 w-8" />
          <span className="text-lg font-bold">Hyundai DRS</span>
        </div>
        <div className="w-[30vw]">
          <Auth view={authView} />
        </div>
        <div className="text-gray-500 text-xs underline cursor-pointer pt-4">
          {authView === "sign_in" ? (
            <span onClick={() => setAuthView("forgotten_password")}>Obnoviť heslo</span>
          ) : authView === "forgotten_password" ? (
            <span onClick={() => setAuthView("sign_in")}>Prihlásiť sa</span>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    // Application
    <div className="h-screen flex flex-col w-screen overflow-x-hidden z-10">
      <Navigation />
      <main className="grow flex bg-hyundai-background z-0 shadow-content">
        <div className="mx-auto max-w-full py-0 sm:px-0 lg:px-0">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default App;
