import { saveAs } from "file-saver";
import { utils, write } from "xlsx";

export function exportExcel(rows: Record<string, any>[], headerMap: { [key: string]: string }, filePrefix: string = "export") {
  let workbook = utils.book_new();
  let worksheet = utils.json_to_sheet(
    rows.map((row) => {
      const remapped = Object.entries(headerMap).map(([colName, _renamed]) => [colName, row[colName]]);
      return Object.fromEntries(remapped);
    }),
    { origin: "A2", skipHeader: true, header: Object.keys(headerMap) }
  );
  utils.sheet_add_aoa(worksheet, [Object.values(headerMap)]);

  utils.book_append_sheet(workbook, worksheet);
  const excelBuffer = write({ Sheets: { data: worksheet }, SheetNames: ["data"] }, { bookType: "xlsx", type: "array" });

  saveAs(
    new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" }),
    `${filePrefix}_${new Date().getTime()}.xlsx`
  );
}