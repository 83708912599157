import { Link } from "react-router-dom";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="h-full flex items-center justify-center">
      <div id="error-page" className="flex flex-col items-center space-y-4 w-[560px]">
        <h1 className="text-hyundai text-2xl font-semibold">
          Oops! {isRouteErrorResponse(error) ? error.status : "Unexpected error occurred"}
        </h1>

        {isRouteErrorResponse(error) ? (
          <div>
            <p>
              <span className="font-semibold">{error.error?.name}</span>: {error.statusText}
            </p>
            {error.data?.message && (
              <p>
                <i>{error.data.message}</i>
              </p>
            )}
          </div>
        ) : (
          <div className="bg-gray-100 p-6 rounded-xl">
            <p className="font-mono text-xs text-red-500">
              <span className="font-semibold">{error instanceof Error ? error.name : "Generic"}: </span>
              <span>{error instanceof Error ? error.message : "No message provided"}</span>
            </p>
          </div>
        )}

        <Link
          to="/"
          className="hover:text-primary inline-block rounded-lg border border-hyundai px-4 py-2 text-center text-sm font-semibold text-white bg-hyundai transition hover:bg-white hover:text-hyundai"
        >
          Back home
        </Link>
      </div>
    </div>
  );
}
